import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { 
    AdminBuydown, 
    AdminConfig, 
    Client, 
    ClientCalculationsConfiguration, 
    ClientDocumentOptions, 
    ClientRegCheckOptions, 
    Delivery,
    DeliveryLoanReview,
    DocuSign,
    DocuSignLoanOfficerSigningConfiguration,
    DocuSignSecurity,
    EOriginal,
    Email,
    EmailFrom,
    EmailSecurity,
    EscrowConfigurations,
    Expiration,
    LoanDetail,
    LoanLock, 
    LoanOfficerSigningOrder, 
    NotaryConfig, 
    Overridable, 
    PrepaidInterestConfigurations, 
    Products, 
    RecipientList, 
    RecipientSelectOptions, 
    RegCheckSuiteOptions, 
    Retention, 
    SecurityOption
} from '@/common/models'
import { adminDataService } from '@/common/services'
import { cloneDeep } from 'lodash'
import { usePageActionsStore } from './page-actions'

export const useClientStore = defineStore('clients', () => {
    const pageActions = usePageActionsStore()
    const client = ref<Client | null>(null)

    const initializedClient = computed(() => {
        const initializedClient = cloneDeep(client.value || ({} as Client))
        initializedClient.adminConfig ??= {} as AdminConfig
        initializedClient.adminConfig.buydown ??= {} as AdminBuydown
        initializedClient.documents ??= {} as ClientDocumentOptions
        initializedClient.documents.rateLockExpiration ??= {} as Expiration
        initializedClient.documents.closingCostEstimateExpiration ??= {} as Expiration
        initializedClient.delivery ??= {} as Delivery
        initializedClient.delivery.docuSign ??= {} as DocuSign
        initializedClient.delivery.docuSign.users ??= []
        initializedClient.delivery.docuSign.deadlines ??= []
        initializedClient.delivery.docuSign.loanOfficerConfiguration ??= {} as DocuSignLoanOfficerSigningConfiguration
        initializedClient.delivery.docuSign.loanOfficerConfiguration.loanOfficerCanEsignDocuments ??= false
        initializedClient.delivery.docuSign.loanOfficerConfiguration.signingOrder ??= LoanOfficerSigningOrder.AfterAllBorrowers
        initializedClient.delivery.docuSign.security ??= {} as DocuSignSecurity
        initializedClient.delivery.docuSign.security.accessCodeParts ??= [] 
        initializedClient.delivery.docuSign.recipientList ??= {} as RecipientList
        initializedClient.delivery.docuSign.recipientList.borrowers ??= {} as RecipientSelectOptions
        initializedClient.delivery.docuSign.recipientList.cosigners ??= {} as RecipientSelectOptions
        initializedClient.delivery.docuSign.recipientList.nonborrowingTitleHolders ??= {} as RecipientSelectOptions
        initializedClient.delivery.docuSign.recipientList.titleContact ??= {} as RecipientSelectOptions
        initializedClient.delivery.docuSign.recipientList.settlementContact ??= {} as RecipientSelectOptions
        initializedClient.delivery.docuSign.recipientList.closer ??= {} as RecipientSelectOptions
        initializedClient.delivery.docuSign.recipientList.funder ??= {} as RecipientSelectOptions
        initializedClient.delivery.docuSign.recipientList.lenderLoanOfficer ??= {} as RecipientSelectOptions
        initializedClient.delivery.docuSign.recipientList.brokerLoanOfficer ??= {} as RecipientSelectOptions
        initializedClient.delivery.docuSign.recipientList.currentUser ??= {} as RecipientSelectOptions
        initializedClient.delivery.email ??= {} as Email
        initializedClient.delivery.email.from ??= {} as EmailFrom
        initializedClient.delivery.email.security ??= {} as EmailSecurity
        initializedClient.delivery.email.security.options ??= {} as SecurityOption
        initializedClient.delivery.email.security.options.loanDetails ??= {} as LoanDetail
        initializedClient.delivery.email.recipientList ??= {} as RecipientList
        initializedClient.delivery.email.recipientList.borrowers ??= {} as RecipientSelectOptions
        initializedClient.delivery.email.recipientList.cosigners ??= {} as RecipientSelectOptions
        initializedClient.delivery.email.recipientList.nonborrowingTitleHolders ??= {} as RecipientSelectOptions
        initializedClient.delivery.email.recipientList.titleContact ??= {} as RecipientSelectOptions
        initializedClient.delivery.email.recipientList.settlementContact ??= {} as RecipientSelectOptions
        initializedClient.delivery.email.recipientList.closer ??= {} as RecipientSelectOptions
        initializedClient.delivery.email.recipientList.funder ??= {} as RecipientSelectOptions
        initializedClient.delivery.email.recipientList.lenderLoanOfficer ??= {} as RecipientSelectOptions
        initializedClient.delivery.email.recipientList.brokerLoanOfficer ??= {} as RecipientSelectOptions
        initializedClient.delivery.email.recipientList.currentUser ??= {} as RecipientSelectOptions
        initializedClient.delivery.eOriginal ??= ({ username: 'Asurity-Integration', advanceSigningEnabled: true, previewIsEnabled: true, eNoteIsEnabled: false } as EOriginal)
        initializedClient.delivery.loanReview ??= {} as DeliveryLoanReview
        initializedClient.calculations ??= {} as ClientCalculationsConfiguration
        initializedClient.calculations.escrow ??= {} as EscrowConfigurations
        initializedClient.calculations.prepaidInterest ??= { decimalPlaces: 3 } as PrepaidInterestConfigurations
        initializedClient.loanLock ??= {} as Overridable<LoanLock>
        initializedClient.loanLock.value ??= {} as LoanLock
        initializedClient.regCheckOptions ??= {} as ClientRegCheckOptions
        initializedClient.regCheckOptions.suiteOptions ??= {} as Overridable<RegCheckSuiteOptions>
        initializedClient.regCheckOptions.suiteOptions.value ??= { enableOfacExtendedSearch: false, enabledTests: [] } as RegCheckSuiteOptions
        initializedClient.regCheckOptions.suiteOptions.value.enabledTests ??= []
        initializedClient.products ??= {} as Overridable<Products>
        initializedClient.products.value ??= {} as Products
        initializedClient.notary ??= {} as NotaryConfig
        initializedClient.preparedBy ??= []
        return initializedClient
    })

    const clientDocuments = computed(() => initializedClient.value.documents || {} as ClientDocumentOptions)
    const clientNotaryConfig = computed(() => initializedClient.value.notary || {} as NotaryConfig)
    const clientDelivery = computed(() => initializedClient.value.delivery || {} as Delivery)
    const clientRetention = computed(() => initializedClient.value.retention || {} as Retention)
    const clientRegCheckEnabledWithinPropel = computed(() => initializedClient.value.adminConfig?.regCheckEnabledWithinPropel?.value ?? false)
    const clientRegCheckOptions  = computed(() => initializedClient.value.regCheckOptions.suiteOptions.value || {} as RegCheckSuiteOptions)
    const clientClosingConditions = computed(() => {
        const conditionsToTransform = initializedClient.value.closingConditions as Map<string, string[]> || ({} as Map<string, string[]>)
        return Object
            .entries(conditionsToTransform)
            .map(([key, value]) => [key, value]) as Array<[string, string[]]>
    })
    
    function updateFeePayeeOptions(newFeePayeeList: string[]) {
        if (client.value){
            client.value.feePayees = [...newFeePayeeList] 
        }
    }

    async function getClient(clientCode: string | undefined) {
        try {
            if (!clientCode) {
                return
            }

            pageActions.setBusyPage(true)
            const clientFromApi = await adminDataService.getClient(clientCode)
            client.value = clientFromApi
        } finally {
            pageActions.setBusyPage(false)
        }
    }

    async function updateClientData(clientDetails: Client) {
        try {
            await pageActions.updateSaveLoading(true)
            let clientRecord = cloneDeep(client.value || {}) as Client
            clientRecord = Object.assign({}, clientRecord, cloneDeep(clientDetails))
            const savedClient = await adminDataService.postClient(clientRecord)
            adminDataService.clearClientCache(clientRecord.code)
            client.value = savedClient
        } finally {
            await pageActions.updateSaveLoading(false)
        }
    }

    async function updateClientRegcheck(clientRegCheck: ClientRegCheckOptions) {
        try {
            if (!client.value) return

            await pageActions.updateSaveLoading(true)
            let regCheckOptions = cloneDeep(client.value?.regCheckOptions || {}) as ClientRegCheckOptions
            regCheckOptions = Object.assign({}, regCheckOptions, cloneDeep(clientRegCheck))
            const savedClient = await adminDataService.patchClientRegCheck(client.value.code, regCheckOptions)
            adminDataService.clearClientCache(client.value.code)
            client.value = savedClient
        } finally {
            await pageActions.updateSaveLoading(false)
        }
    }

    async function updateClientFeePayees(feePayees: string[]) {
        try {
            if (!client.value) return

            await pageActions.updateSaveLoading(true)
            const clientRecord = cloneDeep(client.value || {}) as Client
            const updateClientFeePayees = await adminDataService.postClientFeePayees(clientRecord.code, feePayees)
            adminDataService.clearClientCache(clientRecord.code)
            clientRecord.feePayees = updateClientFeePayees
            client.value = clientRecord
        } finally {
            await pageActions.updateSaveLoading(false)
        }
    }

    return { 
        client, 
        initializedClient,
        clientDocuments,
        clientNotaryConfig,
        clientDelivery,
        clientRetention,
        clientRegCheckEnabledWithinPropel,
        clientRegCheckOptions,
        clientClosingConditions,
        
        updateFeePayeeOptions,
        getClient, 
        updateClientData, 
        updateClientRegcheck,
        updateClientFeePayees 
    }
})