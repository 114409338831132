<template>
    <div>
        <div v-if="title">
            <label :for="itemEntry">{{ title }}</label>
        </div>
        <validation-observer v-slot="{ invalid }" v-if="!readOnly">
            <validation-provider name="new entry" :rules="ruleValidations">
                <div class="input-row" v-if="!limitReached">
                    <input-text
                        v-if="!useAutoComplete && rows === 1"
                        inputType="text"
                        id="itemEntry"
                        v-model.trim="itemEntry"
                        @keyup.native.enter="addItem(invalid)"
                        @change="addItem(invalid || !addOnChange)"
                        :placeholder="inputProps.placeholder"
                        :disabled="readOnly"
                        class="field-entry"
                    />
                    <fg-textarea
                        v-else-if="!useAutoComplete"
                        inputType="text"
                        id="itemEntry"
                        v-model.trim="itemEntry"
                        @keyup.native.enter="addItem(invalid)"
                        @change="addItem(invalid || !addOnChange)"
                        :placeholder="inputProps.placeholder"
                        :disabled="readOnly"
                        :rows="rows"
                        class="field-entry"
                    />
                    <vue-autosuggest
                        v-else
                        ref="vue_autoSuggest"
                        class="field-entry"
                        v-model="query"
                        :suggestions="autoCompleteSuggestions"
                        :input-props="inputProps"
                        @click="handleClick(query)"
                        @input="onAutoCompleteInputChange"
                        @selected="onAutoCompleteSelected"
                        @keyup.native.enter="addItem(invalid)"
                    >
                        <template v-slot="{ suggestion }">
                            <div class="dropdown-item">
                                <div v-if="suggestion.item">
                                    {{ suggestion.item }}
                                </div>
                            </div>
                        </template>
                    </vue-autosuggest>
                    <custom-button class="add-button" icon="Add" :disabled="invalid || readOnly" @click="addItem(invalid)"/>
                    <custom-icon v-if="limit != 1"
                        icon="Help"
                        :id="`itemEntry-help`"
                        :title="`Enter one or more values separated by semicolons, and click the Add button or press ENTER to add them to the list.`"
                    />
                </div>
                <template v-else-if="limit != 1">
                    <i>Up to {{limit}} items are allowed.</i>
                </template>
            </validation-provider>
        </validation-observer>

        <div v-if="items && items.length > 0" class="field-section list-values">
            <content-block-list
                id="contentBlockItemListValues"
                :listItems.sync="items"
                hideAddButton
                deletable
                :draggable="!alphabeticalSort"
                @update:listItems="updateListItems"
                :readOnly="readOnly"
            >
                <template v-slot:view="slotParams">
                    {{ slotParams.item }}
                </template>
            </content-block-list>
        </div>
        <div v-else-if="limit != 1 && underLabel" class="entry-box">
            <label class="empty-label">{{underLabel}}</label>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { AutoCompleteSuggestions } from "@/propel/models"
import { PropType, computed, defineEmits, defineProps, ref, toRefs } from "vue"
import { SortHelper } from "../utilities/helper"

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:"update:items", list: any[])
}>()

const props = defineProps({
    items: { type: Array as PropType<Array<any>>, required: true },
    autoCompleteItems: { type: Array as PropType<Array<string>>, default: () => [] },
    title: { type: String, default: "" },
    validateNumber: { type: Boolean },
    readOnly: { type: Boolean },
    alphabeticalSort: { type: Boolean },
    useAutoComplete: { type: Boolean },
    addOnChange: { type: Boolean },
    showErrorIcon: { type: Boolean, default: true },
    limit: { type: Number, default: null },
    rows: { type: Number, default: 1 },
    underLabel: { type: String, default: "Enter values above to add items to this list." }
})

const vue_autoSuggest = ref<HTMLElement | null>(null)

const query = ref("")
const itemEntry = ref("")
const savedAutoCompleteEntry = ref(false)
const autoCompleteSuggestions = ref([] as AutoCompleteSuggestions[])

const { items } = toRefs(props)
//#endregion

//#region COMPUTED
const ruleValidations = computed(() =>  { return { required: true, regex: props.validateNumber ? /^-?\d*\.?\d*$/ : "" } })
const limitReached = computed(() =>  props.limit !== null && items.value.length >= props.limit)
const inputProps = computed(() => {
    return { id: "autosuggest__input",
        class: "autosuggest__input",
        placeholder: props.limit == 1 ? "Enter a value" : "Add a new value",
        disabled: props.readOnly
    }
})
//#endregion

//#region INITIALIZE
initialize()

function initialize() {
    if (props.useAutoComplete) {
        refreshAutoSuggestions()
    }

    if (props.alphabeticalSort)
        items.value.sort(SortHelper.alphabeticalSort(false))
}
//#endregion

function addItem(invalid: boolean) {
    if (!invalid) {
        if (!props.useAutoComplete) {
            var itemsEntered = itemEntry.value.split(/[;]/).map(i => i.trim()).filter(i => i !== "")
            itemsEntered.forEach((i) => {
                if (!limitReached.value)
                    items.value.push(props.validateNumber ? Number(i) : i)
            })
            itemEntry.value = ""
        } else {
            if (!savedAutoCompleteEntry.value) {
                var queryItems = query.value.split(/[;]/).map(i => i.trim()).filter(i => i !== "")
                queryItems.forEach( i=> {
                    if (!limitReached.value)
                        items.value.push(props.validateNumber ? Number(i) : i)
                })
                const autosuggest = vue_autoSuggest.value as any
                autosuggest.internalValue = ""
                refreshAutoSuggestions()
            }
            savedAutoCompleteEntry.value = false
        }
    }

    if (props.alphabeticalSort)
        items.value.sort(SortHelper.alphabeticalSort(false))}

function updateListItems(value) {
    emit("update:items", value)
}

function dataFilter(): string[] {
    //Filter null values that were present in data and causing errors on toLowerCase()
    const filteredArray = items.value.filter((x) => x)
    const tempArray = filteredArray.map((x) => x.toLowerCase())
    return props.autoCompleteItems.filter((item) => !tempArray.includes(item.toLowerCase()) && item.toLowerCase().indexOf(query.value.toLowerCase()) > -1)
}

function refreshAutoSuggestions() {
    autoCompleteSuggestions.value = [
        {
            data: dataFilter()
        }
    ]
}

function onAutoCompleteInputChange(text) {
    query.value = text
    savedAutoCompleteEntry.value = false
    if (text === undefined) {
        return
    }

    refreshAutoSuggestions()
}

function handleClick(query) {
    if (query === "") {
        refreshAutoSuggestions()
    }
}

function onAutoCompleteSelected(selectedItem) {
    if (selectedItem) {
        items.value.push(selectedItem.item)
        const autosuggest = vue_autoSuggest.value as any
        autosuggest.internalValue = ""
        savedAutoCompleteEntry.value = true
        refreshAutoSuggestions()
    }
}
</script>

<style scoped lang="scss">
.input-row {
    display: flex;
    align-items: center;
}

.add-button {
    margin-left: 0.5rem;
}

.field-entry {
    margin-bottom: 0;
    flex-grow: 1;
}

.list-values {
    margin-top: 0.25rem;
}
</style>