import {
    AdminTrustees,
    ContactType,
    PartialPaymentType,
    ServicingOption,
    Condition,
    TaxFormRequesterContact,
    AuditInfo,
    IncomeTranscript,
    LoanLock, LosIntegrationSetting,
    RegCheckSuiteOptions,
    LenderWarehouseBank,
    PreparedBy,
    Overridable,
    Notary,
    NotaryPublic,
} from '../index'
import { EmailRecipient, DocumentMode } from '@/propel/models'

export interface Client {
    code: string 
    partnerCode: string
    name: string
    documents: ClientDocumentOptions
    delivery: Delivery
    retention: Retention
    lenderProfiles: LenderProfile[]
    interfaceDetails: ClientInterfaceDetails
    useSystemTrustees: boolean
    overwriteTrusteeImportData: boolean
    defaultTaxRequestFormType: DefaultTaxRequestFormType
    trustees: AdminTrustees[]
    useSystemDefaultSortOrder: boolean
    customSortGroupOrder: string[]
    ruleClosingConditions: RuleBasedClosingCondition[]
    useParentRuleConditions: boolean
    // Map<string, string[]> was the original type, as it most closely aligned with the dictionary it is saved as 
    // however due to various issues with using that type most of the components weren't actually using the type, 
    // they were using what the api response got transformed into, Array<[string, string[]]>
    // because this is transformed and recreated I've left it as any 
    closingConditions: any 
    calculations: ClientCalculationsConfiguration
    adminConfig: AdminConfig
    preparedBy: PreparedBy[]
    loanLock: Overridable<LoanLock>
    ecoaNoticeName: string|null
    dataMapping: DataMapping
    regCheckRetention: RegCheckDataRetention
    regCheckDocumentSecurity: SecurityConfig
    regCheckOptions: ClientRegCheckOptions
    businessDays: BusinessCalendar
    legacyClientNumber: string
    warehouseBanks: LenderWarehouseBank[]
    ignoreAuditErrors: boolean
    heloc: Heloc
    stateSpecific: LenderStateSpecific
    audit: AuditInfo
    autoAddNewFeePayees: boolean 
    feePayees: string[]
    products: Overridable<Products>
    embedAllFonts: boolean
    notary: NotaryConfig
}

export interface ClientInterfaceDetails {
    automaticallyCreate4506TW9: Overridable<boolean>
    losIntegrationSettings: Overridable<LosIntegrationSetting>
    interfaceCodes: InterfaceCode[]
    defaultFormsRequested: string[]
    integrationLinks: string[]
}

export interface InterfaceCode {
    appKey: string
}

export interface PackageType {
    code: string
    displayName: string
    isDefault: boolean
}

export interface DataMapping {
    loanIdentifier: string
}

export interface ClientPackageType extends PackageType {
    displayName: string
    statesRequiringLoanReview: string[]
    defaultFeeMode: DocumentMode
}

export interface AdminConfig {
    directBillIndicator: boolean
    clientSpecificAuditChecksOnly: boolean
    invoiceDeedAmount: number
    txDocPrepInvoiceAmount: number
    releaseOfLien: AdminReleaseOfLien
    buydown: AdminBuydown
    packageTypes: ClientPackageType[]
    documentCategories: DocumentCategory[]
    regCheckBillIndicator: boolean
    regCheckEnabledWithinPropel: Overridable<boolean>
}

export interface AdminReleaseOfLien {
    enableReleaseOfLien: boolean
    lienInvoiceAmount: number
}

export interface AdminBuydown {
    enableBuydown: boolean
    enableBorrowerPaidBuydowns: boolean
    invoiceAmount: number
}

export interface DocumentSummaryDto {
    docId: string
    docTitle: string
    category: string
}

export interface DocumentCategory {
    formId: string
    category: string
}

export interface ClientDocumentOptions {
    barcodes: Barcode
    initialLines: InitialLine
    signatures: Signature
    legalDescription: ClientLegalDescription
    rescission: Rescission
    irs4506TInfo: Irs4506TInfo
    security: SecurityConfig
    convertToUppercase: boolean
    convertClosingConditionsToUppercase: boolean
    includeOnlyBorrowerFeesLe: boolean
    isAssumable: boolean
    partialPaymentType: PartialPaymentType | null
    hasDemandFeature: boolean
    liabilityAfterForeclosure: Overridable<LiabilityAfterForeclosure>
    printSignatureForNonObligatedOnPurchaseCd: boolean
    printSignatureForCocLetterCd: boolean
    printNewFHAForms: boolean
    includeSellerTransactionsOnCd: boolean
    excludeEmptyFeesFromCD: boolean
    printSignatureForLe: boolean
    printSignatureForCocLetterLe: boolean
    sellsInsurance: boolean
    isDepositoryInstitution: boolean
    closesMdTitle12: boolean
    bondInsuranceCompanyName: string
    bondExpirationDate: Date
    servicing: ServicingOption
    isLoanNumberRemovedFromRecordableDocuments: boolean
    printUniversalLoanIdentifierOnUrla: boolean
    timeZone: string
    reconsiderationOfValue: ReconsiderationOfValue
    rateLockExpiration: Expiration
    closingCostEstimateExpiration: Expiration
}

export interface NotaryConfig {
    borrowers: BorrowerAcknowledgments
    sellers: SellerAcknowledgments
    lender: LenderAcknowledgments
    mers: LenderAcknowledgments
    assignor: AssignorAcknowledgements
    contractor: ContractorAcknowledgements
    releaseOfLien: BaseAcknowledgements
}

export interface BaseAcknowledgements {
    layout: NotaryLayoutType
    layoutState: string
}

export interface BorrowerAcknowledgments extends BaseAcknowledgements {
    display: NotaryDisplayType
    includeRon: boolean
    allowRonOverride: boolean
}

export interface SellerAcknowledgments extends BaseAcknowledgements {
    display: NotaryDisplayType
    includeRon: boolean
}

export interface LenderAcknowledgments extends BaseAcknowledgements {
    includeRon: boolean
    identificationMethod: string
    notaryPublic: NotaryPublic
}

export interface AssignorAcknowledgements extends BaseAcknowledgements {
    includeRon: boolean
    identificationMethod: string
    entity: string
    stateOfIncorporation: string
    notaryPublic: NotaryPublic
}

export interface ContractorAcknowledgements extends BaseAcknowledgements {
    includeRon: boolean
}

export interface RuleBasedClosingCondition{
    conditions: Condition
    closingConditionText: string
    useLineBreaks?: boolean
}

export interface Barcode {
    type: BarcodeType
    alignment: string
    dataFormat: string
    pages: BarcodePages
}

export interface Expiration {
    time: string
    timeZone: string
}

export enum BarcodePages{
    AllPages = "AllPages",
    FirstPage = "FirstPage"
}

export enum BarcodeType{
    None = "None",
    PDF_417 = "PDF_417",
    CODE_39 = "CODE_39"
}

export enum NotaryDisplayType
{
    Single = "Single",
    PerSigner = "PerSigner",
    None = "None"
}

export enum NotaryLayoutType
{
    PropertyState = "PropertyState",
    ClosingState = "ClosingState",
    OtherState = "OtherState"
}

export enum DefaultTaxRequestFormType {
    DefaultToW9 = "DefaultToW9",
    DefaultBasedOnBorrowerCitizenship = "DefaultBasedOnBorrowerCitizenship",
}

export interface InitialLine {
    includeOnNote: boolean
    includeOnRiders: boolean
    includeOnSecurityInstrument: boolean
    includeOnModification: boolean
    includeOnAssumption: boolean
}

export interface Signature {
    ssnDisplayType: string 
    includeAllSignersInBorrowerHeader: boolean
    excludeDateOnSignatureLine: boolean
}

export interface ClientLegalDescription {
    printOption: LegalDescriptionPrintOption
    attachedDocumentText: string 
}

export interface Irs4506TInfo {
    taxFormRequested: string
    recordTypes: string[]
    period1Requested: Date
    period2Requested: Date
    period3Requested: Date
    period4Requested: Date
    taxFormRequester: TaxFormRequesterContact | null
    incomeTranscript: IncomeTranscript
}

export interface LiabilityAfterForeclosure {
    stateMayProtectLiabilityForUnpaidBalance: string[]
    stateMayNotProtectLiabilityForUnpaidBalance: string[]
}

export enum LegalDescriptionPrintOption {
    EntireDesc = "EntireDesc",
    LimitedLines = "LimitedLines",
    AdditionalDoc = "AdditionalDoc",
    LenderAttach = "LenderAttach"
}

export interface Rescission {
    printNoticeOfCancellationDate: boolean
    printRightOfRescissionCertificationOfConfirmation: boolean
    printRightOfRescissionDate: boolean
}

export interface EmailDelivery {
    delivery: Delivery
    sendList: EmailRecipient[]
    ccList: EmailRecipient[]
    customMessage: string | null
    clientName: string
    streetNumber: string
    emailLinkType: EmailLinkType
}

export interface Delivery {
    docuSign: DocuSign
    email: Email
    eOriginal: EOriginal
    loanReview: DeliveryLoanReview
    notaryCam: NotaryCam
}

export enum FromEmailTypeEnum{
    SpecificSender = "SpecificSender",
    CurrentUser = "CurrentUser"
}

export interface NotaryCam {
    departmentId: string
    enterpriseId: string
    workflowId: string
    forwardings: Forwarding[]
}

export interface DeliveryLoanReview {
    isEnabled: boolean
}

export interface DocuSign {
    accountNumber: string
    apiAccountId: string
    hmacKey: string
    userId: string
    emailAddress: string
    brandId: string 
    users: DocuSignUser[]
    deadlines: DocuSignDeadline[]
    security: DocuSignSecurity
    recipientList: RecipientList
    mustView: RecipientsThatMustView
    loanOfficerConfiguration: DocuSignLoanOfficerSigningConfiguration
}

export interface Forwarding {
    method: ForwardingType
    ftp: Ftp
}

export enum ForwardingType {
    Ftp = 'Ftp'
}

export interface Ftp {
    protocol: FtpProtocolType
    host: string
    folderPath: string
    credentialsSecretName: string
    overwrite: boolean
}

export enum FtpProtocolType {
    SFTP = 'SFTP',
    FTPS = 'FTPS'
}

export interface Email {
    templateId: string
    fromEmailType: FromEmailTypeEnum
    from: EmailFrom
    customTemplateId: string | null
    borrowerName: string
    security: EmailSecurity
    emailLinkType: EmailLinkType
    recipientList: RecipientList
    branding: EmailBranding
}

export interface EmailBranding {
    logo?: string
    primaryColor: string
    secondaryColor: string
    errorColor: string
}

export interface EOriginal {
    url: string
    username: string
    organization: string
    apiKey: string
    advanceSigningEnabled: boolean
    eNoteIsEnabled: boolean
    previewIsEnabled: boolean
}

export enum EmailLinkType {
    SameEmailLink = 'SameEmailLink',
    DifferentEmailLink = 'DifferentEmailLink'
}

export interface EmailSecurity {
    expiration: number    
    minimumSecurityOptionsRequired: number
    options: SecurityOption
}

export interface RecipientList {
    borrowers: RecipientSelectOptions
    cosigners: RecipientSelectOptions
    nonborrowingTitleHolders: RecipientSelectOptions
    titleContact: RecipientSelectOptions
    settlementContact: RecipientSelectOptions
    closer: RecipientSelectOptions
    funder: RecipientSelectOptions
    lenderLoanOfficer: RecipientSelectOptions
    brokerLoanOfficer: RecipientSelectOptions
    currentUser: RecipientSelectOptions
    packageNotificationGroup: RecipientSelectOptions
}

export interface RecipientsThatMustView {
    borrowers: boolean 
    cosigners: boolean
    nonBorrowingTitleHolders: boolean
}

export interface RecipientSelectOptions {
    enabled: boolean
    default: boolean
    defaultCC: boolean
}

export interface SecurityOption {
    systemPassword: boolean
    loanDetails: LoanDetail
}

export interface LoanDetail {
    loanNumber: boolean
    propertyStreetNumber: boolean
    propertyZipCode: boolean
    borrowerSSN: boolean
    borrowerBirthDate: boolean
}

export interface EmailFrom{
    displayName: string
    emailAddress: string
}

export interface DocuSignUser {
    emailAddress: string
    userId: string
    brandId: string
}

export interface DocuSignSecurity {
    enablePhoneAuthentication: boolean
    enableAccessCodeAuthentication: boolean
    accessCodeParts: AccessCode[]
    accessCode: string // Not saved in CB, used to send data to docusign delivery api
}

export interface AccessCode {
    type: AccessCodeType
    code: string
}

export interface DocuSignDeadline {
    packageTypes: string[]
    days: number
}

export interface DocuSignLoanOfficerSigningConfiguration {
    loanOfficerCanEsignDocuments: boolean
    signingOrder: LoanOfficerSigningOrder
}

export enum LoanOfficerSigningOrder {
    AfterAllBorrowers = "AfterAllBorrowers",
    BeforeAnyBorrowers = "BeforeAnyBorrowers",
    SameTimeAsBorrowers = "SameTimeAsBorrowers"
}

export interface Retention {
    documents: number | null
    loanData: number| null
    importData: number| null
    deliveryAudit: number| null
}

export interface RegCheckDataRetention {
    documents: number | null
    importData: number | null
}

export interface LenderProfile {
    id: string | null
    name: string
    code: string | null
    displayName: string
    phone: string
    phoneExt: string
    fax: string
    tollFreePhone: string
    email: string
    attn: string
    hours: string
    licenseId: string
    stateLicenses: StateLicense[]
    fhaId: string
    vaId: string
    stateOfIncorporation: string
    type: string
    typeOtherDescription: string
    signerName: string
    signerTitle: string
    notary: Notary
    address: ClientLenderAddress
    insurableInterest: string
    lienHolderName: string
    titleOfPersonAuthorizedToReleaseLien: string
    defaultContacts: DefaultContact[]
    defaultInvestorCode: string
    noteEndorsementType: string
    noteAddressSyncType: string
    overwriteImportedNoteAddress: boolean
    legacyBranch: string
}

export interface StateLicense
{
    states: string[]
    licenseId: string
    exempt: boolean
    closingLoansUnderUCCC: boolean
    closingLoansUnderTxOCCC: boolean
}

export interface DefaultContact{
    contactId: string
    contactIds: string[]
    type: ContactType
}

export interface ClientLenderAddress {
    street: string 
    unit: string
    city: string 
    county: string
    state: string 
    zip: string 
}

export interface ClientSummary {
    code: string
    name: string
    partnerCode: string
}

export interface ClientSummaryDetails {
    code: string 
    partnerCode: string
    details: Delivery
}

export interface SecurityConfig {
    viewPassword: boolean
    viewPasswordValue: string | null
    editPassword: boolean
    editPasswordType: PackagePasswordType | null
    editPasswordValue: string | null
}

export enum PackagePasswordType {
    ClientProvided = 'ClientProvided',
    SystemGenerated = 'SystemGenerated'
}

export interface ClientClosingConditions {
    clientConditions: Map<string, string[]>
    partnerConditions: Map<string, string[]>
    hasClientConditions: boolean
    hasPartnerConditions: boolean
}

export interface ClientCalculationsConfiguration {
    prepaidInterest: PrepaidInterestConfigurations
    escrow: EscrowConfigurations
    mortgageInsurance: MortgageInsuranceConfigurations
    lenderLoan: LenderLoanConfigurations
    excludePrepaidAndEscrowsForIrrlComparison: boolean
}

export interface PrepaidInterestConfigurations {
    perDiemCalculationType: PerDiemCalculationTypes
    decimalPlaces: number
    oddDaysInterestAprHandlingType: OddDaysInterestAprHandlingType
    use30DayMonthForPerDiemDays: boolean
}

export interface EscrowConfigurations {
    monthlyEscrowRoundingType: Overridable<MonthlyEscrowRoundingType>
    defaultMiCushionMonths: number
    defaultEscrowCushionMonths: number
}

export interface MortgageInsuranceConfigurations {
    upfrontRoundingType: Overridable<UpfrontRoundingType>
}

export interface LenderLoanConfigurations {
    overwriteImportData: boolean
}

export interface ClientRegCheckOptions {
    suiteOptions: Overridable<RegCheckSuiteOptions>
    printOptions: RegCheckPrintOptions
    unacceptableCocTypes: RegCheckUnacceptableCocTypes
    otherCocMapping: OtherCocMapping[]
    smallCreditor: boolean
    smallRuralCreditor: boolean
    isNonProfit: boolean
    isQmExempt: boolean
    isDidmcaExemptIn: string[]
    governmentApprovedLender: boolean
}

export interface RegCheckPrintOptions {
    printStyle: RegCheckPrintStyle
    hideSensitiveData: boolean
    timezone: RegCheckTimezone
}

export interface RegCheckUnacceptableCocTypes {
    fees: Record<string, string[]> 
    prepaids: Record<string, string[]>
    lenderCredit: string[]
}

export enum RegCheckPrintStyle {
    FullAuditReport = "FullAuditReport",
    ShortenedAuditReport = "ShortenedAuditReport",
    CheckSummaryOnly = "CheckSummaryOnly"
}

export enum RegCheckTimezone {
    Eastern = "Eastern",
    Central = "Central",
    Mountain = "Mountain",
    Pacific = "Pacific",
    Alaskan = "Alaskan",
    Hawaiian = "Hawaiian"
}

export interface OtherCocMapping {
    otherText: string
    cocType: string
}

export enum AccessCodeType {
    PropertyZipCode = "PropertyZipCode",
    BorrowerZipCode = "BorrowerZipCode",
    BorrowerBirthYear = "BorrowerBirthYear",
    BorrowerLast4SSN = "BorrowerLast4SSN",
    StaticCode = "StaticCode"
}

export enum PerDiemCalculationTypes {
    Item360 = "Item360",
    Item365 = "Item365",
    Item365Or366 = "Item365Or366"
}

export enum MonthlyEscrowRoundingType{
    Truncate = "Truncate",
    NormalRounding = "NormalRounding"
}

export enum UpfrontRoundingType{
    Truncate = "Truncate",
    NormalRounding = "NormalRounding"
}

export enum OddDaysInterestAprHandlingType {
    Extend = "Extend",
    DotNotExtend = "DoNotExtend"
}

export enum HelocMaturityDateCalculationType
{
    ClosingDate = "ClosingDate",
    FirstPaymentDate = "FirstPaymentDate"
}

export enum HelocScreenAndFieldSetupTypes
{
    Standard = "Standard",
    Legacy = "Legacy"
}

export enum DrawPeriodMinimumPaymentOptions
{
    WillNotReduceOutstandingPrincipal = "WillNotReduceOutstandingPrincipal",
    MayNotFullyRepayOutstandingPrincipal = "MayNotFullyRepayOutstandingPrincipal"
}

export enum HelocPaymentApplicationOptions
{
    FinanceChargesFirst = "FinanceChargesFirst",
    OtherChargesFirst = "OtherChargesFirst"
}

export enum PerDiemDaysTypes {
    ActualDays = "The actual number of days in each month",
    ThirtyDays = "30 days in each month"
}

export enum DayOfTheWeek {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

export enum HolidayDateType {
    SpecificDate = 'SpecificDate',
    NthDayOfWeek = 'NthDayOfWeek',
    LastDayOfWeek = 'LastDayOfWeek',
    SpecificDateOfYear = 'SpecificDateOfYear'
}

export interface Heloc {
    maintenanceFees: MaintenanceFee[]
    lateCharges: LateCharge[]
    minimumRates: HelocRate[]
    maximumRates: HelocRate[]
    terminationTerms: TerminationTerm[]
    maturityDateCalculation: HelocMaturityDateCalculationType
    minimumAdvance: number
    minimumDrawPeriodPayment: number
    minimumBalance: number
    screenAndFieldSetup: HelocScreenAndFieldSetupTypes
    standardTerms: StandardHelocTerms
}

export interface MaintenanceFee {
    description: string
    amount: number
    states: string[]
}

export interface LateCharge {
    gracePeriod: number
    amountDescription: string
    states: string[]
    percentage: number
}

export interface StandardHelocTerms {
    loanRequest: HelocLoanRequest
    creditCardLiability: number
    drawPeriodMinimumPayment: DrawPeriodMinimumPaymentOptions | null
    howPaymentsAreApplied: HelocPaymentApplicationOptions | null
}

export interface HelocLoanRequest {
    writeSpecialChecks: boolean
    authorizePayment: boolean
    requestWithdrawal: boolean
    useCreditCard: boolean
}
export interface HelocRate {
    rate: number
    states: string[]
}

export interface TerminationTerm {
    term: number
    states: string[]
}

export interface LenderStateSpecific {
    california: California
    delaware: Delaware
    massachusetts: Massachusetts
    northDakota: NorthDakota
}

export interface California {
    isLicensedUnderFinanceLendersLaw: boolean
}

export interface Delaware {
    isLicensedUnderMortgageBrokerLicense: boolean
}

export interface Massachusetts {
    licenses: License[]
}

export interface NorthDakota {
    isLicensedUnderResidentialMortgageLenderLicense: boolean
}

export interface License {
    type: string
    number: string
}

export interface Products {
    propelEnabled: boolean
    regCheckEnabled: boolean
}

export interface UsHoliday {
    name: string
    holidayDateType: HolidayDateType
    month: number
    dayOfMonth: number | null
    dayOfWeek: DayOfTheWeek | null
    weekOfMonth: number | null
    yearActiveFrom: number | null
    yearActiveUntil: number | null
    includeObserved: boolean | null
    stateAbbreviations: string[]
    actualDate: Date | null // only in UI
    showActive: boolean // only in UI
}

export interface BusinessCalendar {
    businessDays: DayOfTheWeek[]
    holidaysObserved: UsHoliday[]
}

export interface ReconsiderationOfValue {
    submissionInstructions: string
    requestProcessingDaysCount: number
}