import qs from 'qs'
import { axiosBackendService, axiosLoanService } from './index'
import { 
    LoanSummary, 
    LoanRecord, 
    Cd, 
    LoanLockRequest, 
    ClientError, 
    XmlParseResult, 
    UpdateLenderProfileRequest, 
    UpdateInvestorRequest, 
    Loan, 
    Program, 
    UpdateDocumentModeRequest, 
    ComplianceApiResult,
    PagedCollection,
    ContactDefault, 
    ContactDefaultType,
    RegCheckSuiteOptions
} from '@/common/models'

class LoanService {
    async getAll(params?: URLSearchParams): Promise<PagedCollection<LoanSummary>> {
        const response = await axiosLoanService.get('/', { params })
        return response.data
    }

    async get(id: string, includeCd = true, includeHoepaCheck = false, includeCompliance = false): Promise<LoanRecord | null> {
        const config = {
            params: {
                'includeCd': `${includeCd}`,
                'includeHoepaCheck': `${includeHoepaCheck}`,
                'includeCompliance': `${includeCompliance}`
            }
        }

        const response = await axiosLoanService.get(`/${id}`, config)
        
        return response.data
    }

    async update(loan: LoanRecord): Promise<LoanRecord> {
        const response = await axiosLoanService.post(`/${loan.id}`, loan.data)
        return response.data
    }

    async delete(id: string) {
        const response = await axiosLoanService.delete(`/${id}`)
        return response.data
    }

    async createNewLoan(clientCode: string, loanNumber: string, documentMode: string, loanData: Loan, overwrite = false, applyDefaults = true, originatingSystem: string | null = null, sanitize = false): Promise<LoanRecord> {
        const config = {
            headers: {'Content-Type': 'application/json'},
            params: {
                'mode': `${documentMode}`,
                'loanNumber': `${loanNumber}`,
                'clientCode': `${clientCode}`,
                'overwrite': `${overwrite}`,
                'applyDefaults': `${applyDefaults}`,
                'originatingSystem': originatingSystem,
                'sanitize': `${sanitize}`,
            }
        }

        const response = await axiosLoanService.post('/', loanData, config)
        return response.data
    }

    // "converts" CD Loan by changing key data points, then running data through LoanCreator to overwrite the existing CD loan and create a new MOD loan
    async convertCDLoanToMod(loanId: string): Promise<LoanRecord> {
        const response = await axiosBackendService.post(`/loans/converted-mod?loanId=${loanId}`)
        return response.data
    }

    async ping(id: string): Promise<LoanRecord> {
        const config = {
            headers: {'Content-Type': 'application/json'} // adding header to handle WAF error message for post with no content-type
        }
        const response = await axiosBackendService.post(`loans/${id}/ping`, undefined, config) //passing undefined to satisfy method parameters
        return response.data
    }

    async lock(id: string, request: LoanLockRequest): Promise<LoanRecord> {
        let url = `loans/${id}/lock/`
        url += request.lock ? 'on' : 'off'
        const config = {
            headers: {'Content-Type': 'application/json'}, // adding header to handle WAF error message for post with no content-type
            params: {
                'force': `${request.force ? 'true' : 'false'}` // if it gets passed as undefined otherwise would interpolate directly
            }
        }
        const response = await axiosBackendService.post(url, undefined, config) //passing undefined to satisfy method parameters
        return response.data
    }

    async getPersisted(id: string, returnCachedId: boolean, includeCd = true, includeHoepaCheck = false, includeCompliance = false): Promise<LoanRecord | null> {
        const config = {
            params: {
                'includeCd': `${includeCd}`,
                'includeHoepaCheck': `${includeHoepaCheck}`,
                'includeCompliance': `${includeCompliance}`,
                'returnCachedId': `${returnCachedId}`
            }
        }
        const response = await axiosBackendService.get(`loans/${id}/persisted`, config)
        return response.data
    }

    async commitCacheRecord(id: string): Promise<LoanRecord> {
        const response = await axiosBackendService.post(`loans/${id}/commit`)
        return response.data
    }

    async patch(id: string, patchData: any, includeCd = true, includeHoepaCheck = false, includeCompliance = false): Promise<LoanRecord> {
        const config = {
            params: {
                'includeCd': `${includeCd}`,
                'includeHoepaCheck': `${includeHoepaCheck}`,
                'includeCompliance': `${includeCompliance}`
            }
        }

        const response = await axiosBackendService.patch(`loans/${id}?`, patchData, config)
        return response.data
    }

    async getCdData(id: string): Promise<Cd> {
        const response = await axiosLoanService.get(`/${id}/cd`);
        return response.data
    }
    
    async getFilteredPrograms(loan: Loan, partnerCode: string, clientCode: string): Promise<Program[]> {
        const response = await axiosLoanService.post(`/filtered-programs?partnerCode=${partnerCode}&clientCode=${clientCode}`, loan)
        return response.data
    }
    
    async getCompliance(loanId: string, suiteOptions: RegCheckSuiteOptions = {enabledTests: [], enableOfacExtendedSearch: false}): Promise<ComplianceApiResult> {
        const url = `${loanId}/compliance-report?bindData=true`
        const response = await axiosLoanService.get(url, {
            params: {
                suiteOptions
            },
            //TODO update to maybe handle this in the base axios instance
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: "repeat",  allowDots: true })
            }
        }) 
        return response.data
    }

    async getCompliancePdfUri(loanId: string, suiteOptions: RegCheckSuiteOptions = {enabledTests: [], enableOfacExtendedSearch: false}): Promise<string> {
        const response = await this.getCompliancePdfResponse(loanId, suiteOptions, true)
        return response.data
    }

    async getCompliancePdf(loanId: string, suiteOptions: RegCheckSuiteOptions = {enabledTests: [], enableOfacExtendedSearch: false}): Promise<Blob> {
        const response = await this.getCompliancePdfResponse(loanId, suiteOptions, false)  
        return new Blob([response.data], { type: 'application/pdf'})
    }

    private async getCompliancePdfResponse(loanId: string, suiteOptions: RegCheckSuiteOptions, requestPdfUri: boolean) {
        let url = `${loanId}/compliance-report/pdf`
        url = requestPdfUri ? url + '/uri' : url
        return await axiosLoanService.get(url, {
            responseType: requestPdfUri ? undefined : 'arraybuffer',
            params: { 
                suiteOptions
            },
            //TODO update to maybe handle this in the base axios instance
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: "repeat",  allowDots: true})
            }
        })      
    }

    async getComplianceByTransactionId(transactionId: string): Promise<Blob> {
        const url = `compliance/transactions/${transactionId}`
        const response = await axiosBackendService.get(url, { responseType: 'arraybuffer' })
        return new Blob([response.data], { type: 'application/json'})
    }

    async downloadLoansCsvFileForRegCheck(params?: URLSearchParams) {
        const response = await axiosBackendService.get('compliance/transactions/csv', {
            responseType: 'arraybuffer',
            params: params 
        });
        return new Blob([response.data], {type: "text/csv;charset=utf-8"});
    }

    async parseLoanXml(xml: string, schema: string, sanitize = false, clientCode: string|null = null): Promise<XmlParseResult> {
        let url = '/import/parse'
        const urlParams = new URLSearchParams()
        if (schema)
            urlParams.append('schema', schema)
        if (sanitize)
            urlParams.append('sanitize', 'true')
        if (clientCode)
            urlParams.append('clientCode', clientCode)

        url += `?${urlParams.toString()}`

        const config = {
            headers: {'Content-Type': 'application/xml'}
        }
        const response = await axiosLoanService.post(url, xml, config)
        return response.data
    }

    async createNewLoanFromXml(clientCode: string, loanNumber: string, documentMode: string, xml: string, schema: string, overwrite = false, sanitize = false): Promise<LoanSummary> {
        xml = xml.replace(/[ï»¿]/g, '')
        const config = {
            headers: {'Content-Type': 'application/xml'},
            params: {
                'clientCode': `${clientCode}`,
                'loanNumber': `${loanNumber}`,
                'mode': `${documentMode}`,
                'schema': `${schema}`,
                'overwrite': `${overwrite}`,
                'sanitize': `${sanitize}`,
            }
        }
        const response = await axiosBackendService.post('/import/', xml, config)
        return response.data
    }

    async renameLoan(id: string, newLoanNumber: string){
        const response = await axiosBackendService.post(`loans/${id}/loan-number`, { loanNumber: newLoanNumber })
        return response.data;
    }

    async logClientErrors(error: Error, info: string, userName: string): Promise<string> { 
        const url = `/client-error`
        const errorInfo = {
            userName: userName,
            info: info,
            stack: error.stack
        } as ClientError
        const correlationId = Math.random().toString(16).substr(2, 6).toUpperCase()
        const config = {
            headers: {'x-correlation-id': correlationId}
        } 
               
        try { 
            await axiosBackendService.post(url, errorInfo, config);
            return correlationId
        } catch (ex: any) {
            console.error(`Error logging client`, ex)
            return ''
        }        
    }

    async updateDocumentMode(loanId: string, request: UpdateDocumentModeRequest): Promise<LoanRecord> {
        const response = await axiosBackendService.post(`loans/${loanId}/document-mode`, request)
        return response.data
    }

    async updateLenderProfile(loanId: string, request: UpdateLenderProfileRequest): Promise<LoanRecord> {
        const response = await axiosLoanService.post(`/${loanId}/lender-profile`, request)
        return response.data
    }

    async updateInvestor(loanId: string, request: UpdateInvestorRequest): Promise<LoanRecord> {
        const response = await axiosLoanService.post(`/${loanId}/investor`, request)
        return response.data
    }

    async getDefaultContactsPreview(loanId: string, defaultType: ContactDefaultType, investorCode: string|null, lenderProfileId: string | null): Promise<ContactDefault[]> {
        const response = await axiosLoanService.get(`/${loanId}/default-contacts-preview/?defaultType=${defaultType}` 
            + (lenderProfileId ? `&lenderProfileId=${lenderProfileId}` : '')
            + (investorCode ? `&investorCode=${investorCode}` : ''))
        return response.data
    }

    async getLoanTemplate() {
        const response = await axiosBackendService.get('/loans/treeview/')
        return response.data.nodes
    }
}

export const loanService = new LoanService()
